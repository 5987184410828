<template>
    <div class="ask-edit-entity">
        <popup
            id="open-super-intnet"
            v-if="addEntityDiaVisible"
            @closeEvent="addEntityDiaVisible = false"
        >
            <div slot="popup-name">
                {{ handleType == "add" ? $t('entityManagement.addEntity') : $t('entityManagement.editEntity') }}
            </div>
            <!-- {{$t('entityManagement.entity')}} -->
            <!-- <div slot="popup-tip"></div> -->
            <div id="superIntentConfig-intent-add" slot="popup-con">
                <ul class="addIntentInfo">
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title"
                                >{{$t('entityManagement.addEntityUpdate.name')}} <span class="red-text">*</span>
                            </span>
                            <el-input
                                v-model="entityObject.name"
                                maxlength="32"
                                size="small"
                                :placeholder="$t('entityManagement.addEntityUpdate.namePla')"
                            ></el-input>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title"
                                >{{$t('entityManagement.addEntityUpdate.des')}}<span class="red-text"></span>
                            </span>
                            <el-input
                                v-model="entityObject.description"
                                type="textarea"
                                :rows="5"
                                maxlength="500"
                                :placeholder="$t('entityManagement.addEntityUpdate.desPla')"
                            ></el-input>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title"
                                >{{$t('entityManagement.addEntityUpdate.range')}}<span class="red-text"></span>
                            </span>
                            <el-select
                                :disabled="isEdit"
                                v-model="entityObject.skillId"
                                size="small"
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in skills"
                                    :key="item.skillId"
                                    :label="item.skillName"
                                    :value="item.skillId"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title">{{$t('entityManagement.addEntityUpdate.entityType')}}</span>
                            <div class="ask-el-radio-group">
                                <el-radio-group
                                    size="medium"
                                    :disabled="handleType != 'add'"
                                    v-model="entityObject.type"
                                >
                                    <el-radio label="DICTIONARY"
                                        >{{$t('entityManagement.addEntityUpdate.DICTIONARY')}}</el-radio
                                    >
                                    <el-radio label="REGULAR"
                                        >{{$t('entityManagement.addEntityUpdate.REGULAR')}}</el-radio
                                    >
                                </el-radio-group>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="addEntityDiaVisible = false"
                    plain
                    round
                    class="cancel-btn"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="submitAddEntity"
                    round
                    :loading="addIntentLoading"
                    class="confirm-btn"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "@/components/popupNew";
export default {
    components: { popup },
    props: ["isEdit", "skills", "entity", "handleType"],
    data() {
        return {
            addEntityDiaVisible: false,
            // handleType: "add", // add 新增 edit 编辑
            addIntentLoading: false,
            entityObject: {
                id: null,
                content: [],
                isDefault: false,
                name: "",
                skillId: "",
                type: "DICTIONARY",
            },
        };
    },
    methods: {
        // 提交新建或保存实体
        submitAddEntity() {
            if (this.entityObject.name.trim() == "") {
                this.$message.error("实体名称不能为空");
                return;
            }
            if (
                this.entityObject.skillId == null ||
                this.entityObject.skillId == undefined ||
                this.entityObject.skillId == -1
            ) {
                this.$message.error("适用范围不能为空");
                return;
            }
            // delete this.entityObject.description;
            this.entityObject.content.forEach((item) => {
                delete item.standardWordTamp;
                delete item.standardWordEditing;
                delete item.synonymsTamp;
                delete item.synonymsEditing;
                if(!Array.isArray(item.synonyms)) {
                    item.synonyms = item.synonyms.split(",").filter(item => item !== "");
                }
                
                console.log(144, item.synonyms);
            });
            if (this.entityObject.id) {
                //修改
                this.FetchPut(
                    this.requestUrl.entity.updateEntity,
                    this.entityObject.id,
                    this.entityObject
                )
                    .then((res) => {
                        if (res.code == 0) {
                            this.$emit("afterSave", this.entityObject);
                            localStorage.setItem(
                                "activeEntityObj",
                                JSON.stringify(this.entityObject)
                            );
                        } else {
                            console.log("submitAddEntity", res);
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            //新增
            else
                this.FetchPost(
                    this.requestUrl.entity.updateEntity,
                    this.entityObject
                )
                    .then((res) => {
                        if (res.code == 0) {
                            this.entityObject.id = res.data;
                            this.$emit("afterSave", this.entityObject);
                            localStorage.setItem(
                                "activeEntityObj",
                                JSON.stringify(this.entityObject)
                            );
                        } else {
                            console.log("submitAddEntity", res);
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        },
    },
    mounted() {
        // this.addEntityDiaVisible = this.addIntentDiaVisible;
        this.entityObject = JSON.parse(JSON.stringify(this.entity));
    },
};
</script>
<style lang="less" scoped>
.item-config {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 20px 0px;
    font-size: 14px;
    .title {
        color: #616161;
        .red-text {
            color: red;
        }
    }
    .ask-el-radio-group {
        display: flex;
        align-items: center;
        height: 40px;
        background: #fbfcfd;
        border-radius: 5px;
        text-align: left;
        padding-left: 10px;
    }

    .block-add-intent {
        width: 100%;
        display: flex;
        flex-direction: column;
        .title {
            text-align: left;
            margin-bottom: 10px;
            display: flex;
            align-items: centers;
        }
    }
}
</style>