<template>
    <div id="ask-edit-entity">
        <content-header :isAutoBack="false" :showHelp="false" @goBack="goBack">
            <div slot="content-header-left" class="preleftInfo">
                <div class="pageLeft">
                    <div class="dirInfo">
                        <div class="dirNameStatus">
                            <span>
                                <i
                                    class="iconfont guoran-tongyichicun-shitiguanli"
                                ></i></span
                            >{{ entityObject.name }}
                            <span
                                class="pluginType"
                                :class="
                                    entityObject.type == 'DICTIONARY'
                                        ? 'aee-reg-btn'
                                        : 'aee-dir-btn'
                                "
                            >
                                {{
                                    entityObject.type == "DICTIONARY"
                                        ? $t('entityManagement.editEntityUpdate.enumeration')
                                        : $t('entityManagement.editEntityUpdate.regular')
                                }}
                            </span>
                        </div>
                        <div class="newsDir" v-if="entityObject.description">
                            <i class="iconfont guoran-wentimiaoshu"></i>
                            {{ entityObject.description }}
                        </div>
                    </div>
                    <div class="edit-entity-base" @click="editEntityBaseInfo">
                        <i
                            class="iconfont guoran-tongyichicun-16-15-lianjibianji"
                        ></i>
                    </div>
                </div>
            </div>
            <div slot="content-header-right" class="leftInfo">
                <el-popover
                    placement="top-start"
                    width="100"
                    trigger="click"
                    popper-class="commitLoadMore"
                >
                    <div class="popoverItem" @click="deleteEntity">
                        <i class="iconfont guoran-piliangshanchu"></i>
                        {{$t('common.deleteText')}}
                    </div>
                    <i slot="reference" class="iconfont guoran-qita1"></i>
                </el-popover>
            </div>
        </content-header>
        <div v-show="entityObject.type == 'DICTIONARY'" class="aee-content">
            <div class="aee-contennt-handle">
                <div
                    v-if="!addStandardWordIng"
                    class="aee-contennt-handle-enter"
                >
                    <div class="ache-left">
                        <el-input
                            v-model="seacrhValue"
                            :placeholder="$t('entityManagement.editEntityUpdate.search')"
                            prefix-icon="el-icon-search"
                            @keyup.enter.native="seacrhEntity"
                        ></el-input>
                    </div>
                    <div class="ache-right">
                        <div
                            v-show="!dataConnecting"
                            @click="showDataInterConnect"
                            class="ache-right-btn"
                        >
                            <span class="arb-pre">
                                <i
                                    class="iconfont guoran-tongyichicun-jiekouduijie"
                                ></i>
                            </span>
                            <span v-show="!dataConnecting" class="arb-text"
                                >{{$t('entityManagement.editEntityUpdate.interconnection')}}</span
                            >
                        </div>
                        <div v-show="dataConnecting" class="ache-right-btn">
                            <span class="arb-pre">
                                <img
                                    src="./../../assets/images/5-130H2191536.gif"
                                    alt=""
                                    srcset=""
                                />
                            </span>
                            <span class="arb-text">{{$t('entityManagement.editEntityUpdate.connected')}}</span>
                        </div>
                        <div @click="exportEntity" class="ache-right-btn">
                            <span class="arb-pre">
                                <i
                                    v-show="!exporting"
                                    class="iconfont guoran-tongyichicun-18-19-daochu"
                                ></i>
                                <img
                                    v-show="exporting"
                                    src="./../../assets/images/5-130H2191536.gif"
                                    alt=""
                                    srcset=""
                                />
                            </span>
                            <span class="arb-text"
                                >{{ exporting ? $t('entityManagement.editEntityUpdate.exporting') : $t('entityManagement.editEntityUpdate.export') }}</span
                            >
                        </div>
                        <div class="ache-right-btn" @click="importEntity">
                            <span class="arb-pre">
                                <i
                                    v-show="!importing"
                                    class="iconfont guoran-tongyichicun-piliangdaoru"
                                ></i>
                                <img
                                    v-show="importing"
                                    src=""
                                    alt=""
                                    srcset=""
                                />
                            </span>
                            <span class="arb-text"
                                >{{ importing ? $t('entityManagement.editEntityUpdate.importing') : $t('entityManagement.editEntityUpdate.import') }}</span
                            >
                        </div>
                        <el-button
                            size="small"
                            icon="el-icon-plus"
                            round
                            plain
                            @click="addStandardWordIng = true"
                        >
                          {{$t('entityManagement.editEntityUpdate.addStandardWord')}}
                        </el-button>
                    </div>
                </div>
                <div v-else class="aee-contennt-handle-add">
                    <div class="entity-row-top">
                        <span class="entity-row-top-title" :style="$i18n.locale === 'en' ? 'width:146px' : ''"> {{$t('entityManagement.editEntityUpdate.addStandardWord')}} </span>
                        <el-input
                            class="l"
                            :style="$i18n.locale === 'en' ? 'width:240px' : ''"
                            maxlength="45"
                            :placeholder="$t('entityManagement.editEntityUpdate.wordPla')"
                            v-model="addEntityItem.standardWord"
                            ref="inputStand"
                        />
                        <el-input
                            :class="['r', 'r' + 10000, 'el-input-r']"
                            type="text"
                            :autosize="{ minRows: 1, maxRows: 10 }"
                            :placeholder="$t('entityManagement.editEntityUpdate.synonymPla')"
                            v-model.trim="addEntityItem.synonyms"
                            @input="changeInput($event, addEntityItem, 10000)"
                            @click.stop.native="
                                changeInput(
                                    $event,
                                    addEntityItem,
                                    10000,
                                    'focus'
                                )
                            "
                            ref="inputSynon"
                        />
                        <botKeywordRecommend
                            :key="'addEntity' + 10000"
                            :showBotKeywordRecommend.sync="
                                addEntityItem.showBotKeywordRecommend
                            "
                            :dataObj="addEntityItem"
                            :keyword="addEntityItem.synonyms"
                            :dataIndex="10000"
                            :width="clientWidth"
                            :isFixed="true"
                            :left="positionLeft"
                            :positionTop="positionTop"
                            @noBotKeywordRecommend="
                                closeBotKeywordRecommend(addEntityItem)
                            "
                            @closeBotKeywordRecommend="
                                closeBotKeywordRecommend(addEntityItem)
                            "
                            @addToInput="
                                addToInput($event, addEntityItem, 10000)
                            "
                        >
                        </botKeywordRecommend>
                        <span
                            class="el-icon-success add-icon"
                            @click="addWord"
                        />
                        <span
                            class="el-icon-error delete-icon"
                            @click="addStandardWord(false)"
                        />
                    </div>
                </div>
            </div>
            <div class="aee-contennt-header">
                <span class="ach-text ach-text-num">{{$t('entityManagement.editEntityUpdate.index')}}</span>
                <span class="ach-text ach-text-standard">{{$t('entityManagement.editEntityUpdate.standard')}}</span>
                <span class="ach-text ach-text-thesaurus">
                    {{$t('entityManagement.editEntityUpdate.synonym')}}
                    <span class="ach-text-des">{{$t('entityManagement.editEntityUpdate.synonymTip')}}</span>
                </span>
                <span class="ach-text ach-text-handle" :style="$i18n.locale === 'en' ? 'width:76px' : ''">{{$t('entityManagement.editEntityUpdate.operation')}}</span>
            </div>
            <div
                v-if="entityObject.content.length !== 0"
                class="aee-contennt-list"
            >
                <div
                    class="entity-content-row"
                    v-for="(item, index) in entityObject.content"
                    :key="index"
                >
                    <span class="ach-text ach-text-num">{{ index + 1 }}</span>
                    <div class="l">
                        <div v-if="!item.standardWordEditing" class="acrt-pre">
                            <span
                                @click="changeStandardWordEditing(index, true)"
                                class="acrt-pre-icon"
                            >
                                <i
                                    class="iconfont guoran-tongyichicun-16-15-lianjibianji"
                                ></i>
                            </span>
                            <span class="acrt-pre-text">{{
                                item.standardWord
                            }}</span>
                        </div>
                        <div v-else class="acrt-edit">
                            <el-input
                                maxlength="45"
                                :placeholder="$t('entityManagement.editEntityUpdate.standardWords2')"
                                v-model.trim="item.standardWordTamp"
                                @input="changeStandardWordTamp($event, index)"
                                size="small"
                            />
                            <span
                                class="el-icon-success add-icon"
                                @click="editWord('standardWord', index)"
                            />
                            <span
                                class="el-icon-error delete-icon"
                                @click="changeStandardWordEditing(index, false)"
                            />
                        </div>
                    </div>
                    <div :class="['r', 'r' + index]">
                        <div v-if="!item.synonymsEditing" class="acrs-pre">
                            <span
                                @click="changeSynonymsEditing(index, true)"
                                class="acrt-pre-icon"
                            >
                                <i
                                    class="iconfont guoran-tongyichicun-16-15-lianjibianji"
                                ></i>
                            </span>
                            <span class="acrt-pre-text">{{
                                item.synonyms
                            }}</span>
                        </div>
                        <div v-else class="acrs-edit">
                            <el-input
                                :class="['acrs-edit-r-' + index]"
                                type="text"
                                size="small"
                                :autosize="{ minRows: 1, maxRows: 10 }"
                                :placeholder="$t('entityManagement.editEntityUpdate.synonyms2')"
                                v-model.trim="item.synonymsTamp"
                                @input="changeItemInput($event, item, index)"
                                @click.stop.native="
                                    changeItemInput(
                                        $event,
                                        item,
                                        index,
                                        'focus'
                                    )
                                "
                                ref="inputSynon"
                            />
                            <span
                                class="el-icon-success add-icon"
                                @click="editWord('synonyms', index)"
                            />
                            <span
                                class="el-icon-error delete-icon"
                                @click="changeSynonymsEditing(index, false)"
                            />
                        </div>
                    </div>

                    <botKeywordRecommend
                        :key="'addEntity' + index"
                        :showBotKeywordRecommend.sync="
                            item.showBotKeywordRecommend
                        "
                        :dataObj="item"
                        :keyword="item.synonymsTamp"
                        :dataIndex="index"
                        :width="clientWidth"
                        :isFixed="true"
                        :left="positionLeft"
                        :positionTop="positionTop"
                        @noBotKeywordRecommend="closeBotKeywordRecommend(item)"
                        @closeBotKeywordRecommend="
                            closeBotKeywordRecommend(item)
                        "
                        @addToInput="addToInpuItem($event, item, index)"
                    >
                    </botKeywordRecommend>
                    <span
                        class="iconfont guoran-tongyichicun-16-09-shanchu2"
                        :style="$i18n.locale === 'en' ? 'width:52px' : ''"
                        @click="delWord(index)"
                    />
                </div>
            </div>
            <div v-if="entityObject.content.length === 0" class="no-data-box">
                <img
                    style="width: 300px; height: 200px; margin-top: 80px"
                    src="./../../assets/images/empty.png"
                    alt=""
                />
                <div>{{$t('common.empty')}}</div>
            </div>
            <pagination
                :pageSize="pageSize"
                :currentPage="pageNum"
                :total="recordTotal"
                @handleSizeChange="recordPageSizeChange"
                @currentChange="recordPageChange"
            >
            </pagination>
        </div>
        <div v-show="entityObject.type == 'REGULAR'" class="aee-content">
            <div class="aee-content-tip">
                <div class="aee-content-tip-text">
                    {{$t('entityManagement.editEntityUpdate.regularTip1')}}
                    <br />
                    {{$t('entityManagement.editEntityUpdate.regularTip2')}}
                    <br />
                    {{$t('entityManagement.editEntityUpdate.regularTip3')}}：[1-9][0-9]{4,}
                    <br />
                    {{$t('entityManagement.editEntityUpdate.regularTip4')}}：(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])[
                    -]?(\d{4})[ -]?(\d{4})
                    <br />
                    {{$t('entityManagement.editEntityUpdate.regularTip5')}}：^[a-zA-Z][a-zA-Z0-9_-]{5,19}$
                    <br />
                    {{$t('entityManagement.editEntityUpdate.regularTip6')}}：^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$
                </div>
            </div>
            <div class="aee-content-reg">
                <div class="aee-content-reg-header">
                    <div>
                        <span class="acrh-type">{{$t('entityManagement.editEntityUpdate.regular')}}</span>
                        <span>{{$t('entityManagement.regular')}}</span>
                    </div>
                    <div>
                        <el-button
                            @click="saveReg"
                            type="primary"
                            size="mini"
                            round
                            >{{$t('common.save')}}</el-button
                        >
                    </div>
                </div>
                <div
                    v-if="entityObject.type == 'REGULAR'"
                    class="aee-content-reg-input"
                >
                    <el-input
                        type="textarea"
                        :rows="10"
                        :placeholder="$t('entityManagement.regularPla')"
                        v-model="entityObject.content[0].pattern"
                    >
                    </el-input>
                </div>
            </div>
        </div>
        <editentityDia
            ref="addEntityDia"
            :entity="entityObject"
            handleType="edit"
            :isEdit="isEdit"
            :addIntentDiaVisible="addIntentDiaVisible"
            @afterSave="afterSave"
        ></editentityDia>
        <el-dialog
            id="deleteConfirm"
            :close-on-click-modal="false"
            :visible.sync="deleteVisible"
            :title="$t('entityManagement.editEntityUpdate.deleteEntity')"
            width="421px"
        >
            <span class="content"
                >{{$t('entityManagement.editEntityUpdate.deleteTip')}}</span
            >
            <br />
            <br />
            <br />
            <div class="buttons">
                <el-button plain size="mini" @click="deleteEntityCancel"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="danger"
                    plain
                    size="mini"
                    @click="deleteEntityOk"
                    >{{$t('common.deleteText')}}</el-button
                >
            </div>
        </el-dialog>
        <popup
            id="open-super-intnet"
            v-show="dataInterConnectVisible"
            @closeEvent="dataInterConnectVisible = false"
        >
            <div slot="popup-name">{{$t('entityManagement.editEntityUpdate.dataInterConnectTitle')}}</div>
            <div slot="popup-tip">{{$t('entityManagement.editEntityUpdate.dataInterConnectTip')}}</div>
            <div id="superIntentConfig-intent-add" slot="popup-con">
                <dataconnection-operate
                    ref="dataconnectionoperateRef"
                    :entityId="entityObject.id"
                    @changeDataConnecting="changeDataConnecting"
                    @getNewEntityWord="getNewEntityWord"
                ></dataconnection-operate>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="dataInterConnectVisible = false"
                    plain
                    round
                    class="cancel-btn"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="submitDataInter"
                    round
                    class="confirm-btn"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
        <batchImportIntnet
            :importFileVisible.sync="importFileVisible"
            :entityId="entityObject.id"
            @getNewEntityWord="getNewEntityWord"
        >
        </batchImportIntnet>
    </div>
</template>
<script>
import contentHeader from "../../components/content-top-header.vue";
import editentityDia from "./addEntityupdate.vue";
import botKeywordRecommend from "../intentQa/components/botKeywordRecommend.vue";
import pagination from "@/views/fissionMarketing/components/pagination";
import popup from "@/components/popupNew";
import dataconnectionOperate from "./dataconnectionOperate.vue";
import batchImportIntnet from "./importEntity.vue";
export default {
    data() {
        return {
            entityObject: {
                id: 1150,
                name: "性别",
                type: "DICTIONARY",
                isOpen: true,
                isDefault: false,
                content: [
                    {
                        standardWord: "",
                        synonyms: [],
                        pattern: null,
                        description: null,
                    },
                ],
                createTime: "2022-01-26T02:27:14.000+00:00",
                updateTime: "2022-01-26T02:27:14.000+00:00",
                skillId: 0,
            },
            skillsOptionsTamp: [],
            skillsOptions: [],
            skills: [],
            isEdit: true,
            isDefault: false,
            addIntentDiaVisible: true,
            deleteVisible: false,

            // 枚举编辑
            seacrhValue: "",
            dataConnecting: false,
            exporting: false,
            importing: false,
            addStandardWordIng: false,
            addEntityItem: {
                standardWord: "",
                synonyms: [],
                pattern: "",
            },
            clientWidth: null,
            positionTop: 0,
            positionLeft: 0,
            isIntentFocus: false,

            pageSize: 20,
            pageNum: 1,
            recordTotal: 0,

            dataInterConnectVisible: false,

            importFileVisible: false,

            allowSearchWord: false,
            bId: "",
            skillId: ""
        };
    },
    created() {},
    components: {
        contentHeader,
        editentityDia,
        botKeywordRecommend,
        pagination,
        dataconnectionOperate,
        popup,
        batchImportIntnet,
    },
    methods: {
        exportEntity() {
            if (!this.exporting) {
                this.exporting = true;
                this.FetchPost(
                    this.requestUrl.entity.entityExport +
                        "?entityId=" +
                        this.entityObject.id
                )
                    .then((res) => {
                        if (res.code === "0") {
                            this.exporting = false;
                            window.location.href = res.data;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        importEntity() {
            if (!this.importing) {
                this.importFileVisible = true;
            }
        },
        showDataInterConnect() {
            this.dataInterConnectVisible = true;
        },
        submitDataInter() {
            this.$refs.dataconnectionoperateRef.saveDataInter(() => {
                this.dataInterConnectVisible = false;
            });
        },
        changeDataConnecting(value) {
            console.log(594);
            this.dataConnecting = value;
        },
        changeStandardWordTamp(value, index) {
            this.entityObject.content[index].standardWordTamp = value;
            this.$forceUpdate();
        },
        recordPageSizeChange(value) {
            this.pageSize = value;
            this.getNewEntityWord();
        },
        recordPageChange(value) {
            this.pageNum = value;
            this.getNewEntityWord();
        },
        getNewEntityWord() {
            console.log(625, this.entityObject.type);
            if (this.entityObject.type !== "DICTIONARY") {
                if (
                    this.entityObject.content == null ||
                    this.entityObject.content.length == 0
                ) {
                    this.entityObject.content = [
                        {
                            standardWord: "",
                            synonyms: [],
                            pattern: "",
                            description: "",
                        },
                    ];
                }
                return;
            }
            console.log(625);
            this.FetchGet(
                this.requestUrl.entity.getSingEntityInfo + this.entityObject.id,
                {
                    page: this.pageNum,
                    size: this.pageSize,
                }
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.entityObject.content = res.data.list;
                        if (this.entityObject.type == "DICTIONARY") {
                            if (!this.entityObject.content) {
                                this.entityObject.content = [];
                            }
                            this.entityObject.content.forEach((item) => {
                                item.standardWordTamp = item.standardWord;
                                item.standardWordEditing = false;
                                if (item.synonyms == null) {
                                    item.synonyms = [];
                                }
                                item.synonymsTamp = item.synonyms.join(",");
                                item.synonyms = item.synonyms.join(",");
                                item.synonymsEditing = false;
                            });
                        }
                        this.recordTotal = res.data.total;
                    } else {
                        this.entityObject.content = [];
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        changeStandardWordEditing(index, value) {
            this.$set(
                this.entityObject.content[index],
                "standardWordEditing",
                value
            );
            this.$set(
                this.entityObject.content[index],
                "standardWordTamp",
                this.entityObject.content[index].standardWord
            );
            this.entityObject.content = [...this.entityObject.content];
        },
        changeSynonymsEditing(index, value) {
            this.$set(
                this.entityObject.content[index],
                "synonymsEditing",
                value
            );
            this.$set(
                this.entityObject.content[index],
                "synonymsTamp",
                this.entityObject.content[index].synonyms
            );
            this.$set(
                this.entityObject.content[index],
                "showBotKeywordRecommend",
                false
            );
            this.entityObject.content = [...this.entityObject.content];
        },
        addStandardWord(value) {
            this.addStandardWordIng = value;
            if (!value) {
                // 初始化标准词添加
                this.addEntityItem = {
                    standardWord: "",
                    synonyms: [],
                    pattern: "",
                };
            }
        },
        saveChangeEntityContent(type, word, param, callback) {
            let url =
                this.requestUrl.entity.entityContentChange +
                "?changeType=" +
                type;
            if (type !== "ADD") {
                url = url + "&word=" + word;
            }
            this.FetchPost(url, param)
                .then((res) => {
                    if (res.code === "0") {
                        console.log(res.data);
                        if (type !== "UPDATE") {
                            this.getNewEntityWord();
                        }
                        if (callback) {
                            callback();
                        }
                        this.$message.success(this.$t('entityManagement.editEntityUpdate.updateSuccess'));
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        saveReg() {
            if (this.entityObject.content[0].pattern.trim() === "") {
                this.$message.error(this.$t('entityManagement.regularPla'));
                return;
            }
            let param = {
                id: this.entityObject.id, // 实体ID
                entityName: this.entityObject.name,
                entityDescription: this.entityObject.description,
                entityContent: {
                    standardWord: "",
                    synonyms: [],
                    pattern: this.entityObject.content[0].pattern,
                },
            };
            this.saveChangeEntityContent("UPDATE", "", param, () => {});
        },
        addWord() {
            if (
                this.entityObject.type == "DICTIONARY" &&
                this.addEntityItem.standardWord.trim() === ""
            ) {
                this.$message.error(this.$t('entityManagement.editEntityUpdate.wordPla'));
                return;
            }
            if (Array.isArray(this.addEntityItem.synonyms)) {
                this.addEntityItem.synonyms =
                    this.addEntityItem.synonyms.join(",");
            }
            let param = {
                id: this.entityObject.id, // 实体ID
                entityName: this.entityObject.name,
                entityDescription: this.entityObject.description,
                entityContent: {
                    standardWord: this.addEntityItem.standardWord,
                    synonyms: this.addEntityItem.synonyms
                        .replace(/，/g, ",")
                        .split(","),
                    pattern: this.addEntityItem.pattern,
                },
            };
            this.saveChangeEntityContent(
                "ADD",
                this.addEntityItem.standardWord,
                param,
                () => {
                    this.addEntityItem = {
                        standardWord: "",
                        synonyms: "",
                        pattern: "",
                    };
                }
            );
        },
        delWord(index) {
            let handleObj = this.entityObject.content[index];
            if (Array.isArray(handleObj.synonyms)) {
                handleObj.synonyms = handleObj.synonyms.join(",");
            }
            let param = {
                id: this.entityObject.id, // 实体ID
                entityName: this.entityObject.name,
                entityDescription: this.entityObject.description,
                entityContent: {
                    standardWord: handleObj.standardWord,
                    synonyms: handleObj.synonyms
                        .replace(/，/g, ",")
                        .split(",")
                        .filter(function (item) {
                            return item !== "";
                        }),
                    pattern: handleObj.pattern,
                },
            };
            this.saveChangeEntityContent(
                "DELETE",
                handleObj.standardWord,
                param,
                () => {}
            );
        },
        editWord(type, index) {
            // type standardWord synonyms
            let handleObj = this.entityObject.content[index];
            if (Array.isArray(handleObj.synonyms)) {
                handleObj.synonyms = handleObj.synonyms.join(",");
            }
            let entityContent = {
                standardWord: handleObj.standardWord,
                synonyms: handleObj.synonyms
                    .replace(/，/g, ",")
                    .split(",")
                    .filter(function (item) {
                        return item !== "";
                    }),
                pattern: handleObj.pattern,
            };
            if (type === "standardWord") {
                entityContent.standardWord = handleObj.standardWordTamp;
            }
            if (type === "synonyms") {
                entityContent.synonyms = handleObj.synonymsTamp
                    .replace(/，/g, ",")
                    .split(",")
                    .filter(function (item) {
                        return item !== "";
                    });
            }
            let param = {
                id: this.entityObject.id, // 实体ID
                entityName: this.entityObject.name,
                entityDescription: this.entityObject.description,
                entityContent: entityContent,
            };
            this.saveChangeEntityContent(
                "UPDATE",
                handleObj.standardWord,
                param,
                () => {
                    // let tampEntityContent = JSON.parse(
                    //     JSON.stringify(entityContent)
                    // );
                    // console.log("tampEntityContent", tampEntityContent);
                    if (type === "standardWord") {
                        // tampEntityContent.standardWord =
                        //     tampEntityContent.standardWordTamp;
                        // tampEntityContent.standardWordEditing = false;
                        this.entityObject.content[index].standardWord =
                            this.entityObject.content[index].standardWordTamp;
                        this.entityObject.content[
                            index
                        ].standardWordEditing = false;
                    }
                    if (type === "synonyms") {
                        // tampEntityContent.synonyms =
                        //     tampEntityContent.synonymsTamp;
                        // tampEntityContent.synonymsEditing = false;
                        this.entityObject.content[index].synonyms =
                            this.entityObject.content[index].synonymsTamp;
                        this.entityObject.content[
                            index
                        ].synonymsEditing = false;
                        this.entityObject.content[index].showBotKeywordRecommend = false;
                    }
                    this.$forceUpdate();
                    // this.entityObject.content[index] = tampEntityContent;
                }
            );
        },
        // 关闭近义词推荐弹框
        closeBotKeywordRecommend(item) {
            item.showBotKeywordRecommend = false;
            this.$forceUpdate();
        },
        //添加实体 关键词的近义词 , 单个添加
        addToInput(datas, item, index) {
            console.log(458, datas, item, index);
            item.synonyms = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate();
        },
        //添加实体 关键词的近义词 , 单个添加
        addToInpuItem(datas, item, index) {
            console.log(458, datas, item, index);
            item.synonymsTamp = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate();
        },
        // 添加实体 显隐推荐词下拉框
        changeInput(datas, item, index) {
            this.isIntentFocus = true;
            this.clientWidth = document.querySelector(
                ".entity-row-top .el-input-r"
            ).clientWidth;
            this.entityObject.content.forEach((v, i) => {
                v.showBotKeywordRecommend = false;
            });
            if (item.synonyms !== "") {
                item.showBotKeywordRecommend = true;
                this.$nextTick(() => {
                    let ele = document.querySelector(
                        ".aee-contennt-handle-add .entity-row-top .r" +
                            index +
                            " .el-input__inner"
                    );
                    this.positionTop = ele.getBoundingClientRect().top + 45;
                    this.positionLeft = ele.getBoundingClientRect().left;
                });
            }
            this.$forceUpdate();
        },
        // 添加实体 显隐推荐词下拉框
        changeItemInput(datas, item, index) {
            console.log(920);
            this.isIntentFocus = true;
            this.clientWidth = document.querySelector(
                ".entity-content-row .acrs-edit-r-" + index
            ).clientWidth;
            if (this.clientWidth > 500) {
                this.clientWidth = 500;
            }
            this.entityObject.content.forEach((v, i) => {
                v.showBotKeywordRecommend = false;
            });
            if (item.synonymsTamp !== "") {
                item.showBotKeywordRecommend = true;
                this.$nextTick(() => {
                    let ele = document.querySelector(
                        ".aee-contennt-list .entity-content-row .r" +
                            index +
                            " .el-input__inner"
                    );
                    this.positionTop = ele.getBoundingClientRect().top + 45;
                    this.positionLeft = ele.getBoundingClientRect().left;
                });
            }
            this.$forceUpdate();
        },
        seacrhEntity() {
            if(!this.seacrhValue || /^ *$/.test(this.seacrhValue)) {
                this.getNewEntityWord()
                return
            }
            this.pageNum = 1;
            this.FetchGet(
                this.requestUrl.entity.getSingEntityInfo + this.entityObject.id,
                {
                    page: this.pageNum,
                    size: this.pageSize,
                    word: this.seacrhValue,
                }
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.entityObject.content = res.data.list;
                        if (this.entityObject.type == "DICTIONARY") {
                            if (!this.entityObject.content) {
                                this.entityObject.content = [];
                            }
                            this.entityObject.content.forEach((item) => {
                                item.standardWordTamp = item.standardWord;
                                item.standardWordEditing = false;
                                item.synonymsTamp = item.synonyms.join(",");
                                item.synonyms = item.synonyms.join(",");
                                item.synonymsEditing = false;
                            });
                        }
                        this.recordTotal = res.data.total;
                    } else {
                        this.entityObject.content = [];
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        deleteEntityCancel() {
            this.deleteVisible = false;
        },
        deleteEntityOk() {
            this.FetchDelete(
                this.requestUrl.entity.deleteEntity,
                this.entityObject.id
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.$router.back();
                        this.deleteVisible = false;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        editEntityBaseInfo() {
            this.isEdit = true;
            this.$refs.addEntityDia.addEntityDiaVisible = true;
            this.$refs.addEntityDia.entityObject = JSON.parse(
                JSON.stringify(this.entityObject)
            );
            this.$refs.addEntityDia.skills = this.skillsOptions;
        },
        deleteEntity() {
            this.deleteVisible = true;
        },
        afterSave(entityObject) {
            this.$refs.addEntityDia.addEntityDiaVisible = false;
            this.entityObject = entityObject;
            if (this.entityObject.type == "DICTIONARY") {
                this.entityObject.content.forEach((item) => {
                    console.log(629, item.synonyms);
                    item.standardWordTamp = item.standardWord;
                    item.standardWordEditing = false;
                    item.synonymsTamp = item.synonyms.join(",");
                    item.synonyms = item.synonyms.join(",");
                    console.log(634, item.synonyms);
                    item.synonymsEditing = false;
                });
            }
        },
        goBack() {
            // this.$router.back();
            this.$router.push({ path: '/main/entityManagement', query: { bId: this.bId, skillId: this.skillId } });
        },
        getEntitySkills() {
            this.FetchGet(this.requestUrl.entity.getEntitySkills, {
                isDefault: this.isDefault,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.skills = res.data;
                        this.skillsOptions = JSON.parse(
                            JSON.stringify(res.data)
                        );
                        this.skillsOptionsTamp = JSON.parse(
                            JSON.stringify(this.skillsOptions)
                        );
                        // if (
                        //     this.RP_Visible("OPEN_ENTITY_COMMON_ADD") &&
                        //     !this.RP_Visible("OPEN_ENTITY_ADD")
                        // ) {
                        //     this.skillsOptionsTamp.length = 1;
                        // }
                        // if (
                        //     !this.RP_Visible("OPEN_ENTITY_COMMON_ADD") &&
                        //     this.RP_Visible("OPEN_ENTITY_ADD")
                        // ) {
                        //     this.skillsOptionsTamp.shift();
                        // }
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
    mounted() {
        this.isDefault = this.$route.params.isDefault || false;
        console.log(this.$route.params);
        this.bId = this.$route.params.bId;
        this.skillId = this.$route.params.skillId;
        this.getEntitySkills();
        this.entityObject = JSON.parse(localStorage.getItem("activeEntityObj"));
        this.getNewEntityWord();

        setTimeout(() => {
            this.allowSearchWord = true;
        }, 2000);

        console.log("this.entityObject:", this.entityObject);
    },
};
</script>
<style lang="less" scoped>
.pageLeft {
    display: flex;
    align-items: center;
    .popoverItem {
        width: 100px;
    }
    .leftInfo {
        display: inline-block;
        margin-right: 30px;
    }
    .dirInfo {
        max-width: 600px;
        .iconfont {
            padding: 0 !important;
        }
        .guoran-wentimiaoshu {
            color: #cbd3e2 !important;
        }
        .dirNameStatus {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 20px;
            line-height: 20px;

            i {
                margin-right: 6px;
                font-size: 14px;
            }

            .guoran-chajianguanli {
                font-size: 18px !important;
            }

            .newsState {
                flex: none;
                width: 44px;
                height: 18px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                border-radius: 2px;
                margin-left: 6px;
            }

            .pluginType {
                flex: none;
                padding: 0 8px;
                height: 16px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 2px;
                border-radius: 2px;
                margin-left: 6px;
            }

            .aee-dir-btn {
                background: #e9f9ec;
                color: #0ac02c;
            }

            .aee-reg-btn {
                background: #ecf1ff;
                color: #366aff;
            }
        }

        .newsDir {
            color: #a9b3c6;
            font-size: 12px;
            margin-top: 4px;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;

            i {
                font-size: 12px;
                color: #a9b3c6;
                margin-right: 4px;
            }
        }
    }

    .edit-entity-base {
        margin-left: 10px;
        cursor: pointer;
        width: 24px;
        height: 24px;
        background: #edf2ff;
        border-radius: 5px;
        border: 1px solid #a1b9ff;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            padding: 0 !important;
            color: #366aff !important;
            font-size: 12px;
        }
    }
}
#ask-edit-entity {
    height: 100vh;
    padding: 14px;
    .aee-content {
        height: calc(100vh - 105px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 14px;
        background-color: white;
        position: relative;
        .aee-content-reg {
            padding: 26px;
            .aee-content-reg-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .acrh-type {
                    display: inline-block;
                    width: 31px;
                    height: 18px;
                    background: #e9f9f8;
                    border-radius: 2px;
                    border: 1px solid #d4f1f0;
                    text-align: center;
                    line-height: 18px;
                    color: #0ac02c;
                    margin-right: 4px;
                }
            }
            .aee-content-reg-input {
                margin-top: 10px;
            }
        }
        .aee-content-tip {
            background-color: #f5f7fb;
            padding-bottom: 14px;
            .aee-content-tip-text {
                background: #fff8f4;
                border-radius: 5px;
                border: 1px solid #ffd9c5;
                color: #ff9555;
                font-weight: bold;
                font-size: 12px;
                padding: 14px;
                text-align: left;
            }
        }
        .aee-contennt-header {
            height: 50px;
            background: #f6f8fd;
            display: flex;
            align-items: center;
            .ach-text {
                font-weight: bold;
                text-align: left;
                padding-left: 16px;
            }
            .ach-text-num {
                width: 70px;
                flex: none;
            }
            .ach-text-standard {
                width: 150px;
                flex: none;
            }
            .ach-text-thesaurus {
                flex: auto;
                .ach-text-des {
                    font-size: 12px;
                    color: #a9b3c6;
                }
            }
            .ach-text-handle {
                width: 46px;
                flex: none;
            }
        }
        .aee-contennt-handle-enter {
            display: flex;
            height: 56px;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            padding: 0 13px;
            .ache-left {
                display: flex;
                height: 30px;
                align-items: center;
                flex: 1;
                i {
                    font-size: 18px;
                    cursor: pointer;
                }
                .line {
                    height: 22px;
                    width: 1px;
                    background-color: #e0e6f7;
                    margin: 10px;
                }
                /deep/ .el-input__inner {
                    border: none;
                }
            }
            .ache-right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                /deep/ .el-button {
                    //width: 94px;
                    padding: 9px 8px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #366aff;
                    border: 1px solid #366aff;
                    border-radius: 5px;
                    font-size: 12px;
                    color: #fff;
                }
                .ache-right-btn {
                    height: 30px;
                    line-height: 30px;
                    background: #ffffff;
                    border-radius: 5px;
                    border: 1px solid #a1b9ff;
                    color: #366aff;
                    font-size: 12px;
                    padding: 0 8px;
                    margin-right: 8px;
                    cursor: pointer;
                    .arb-pre {
                        img {
                            height: 16px;
                            width: 16px;
                            margin-bottom: -4px;
                        }
                        i {
                            display: inline-block;
                            margin-right: 4px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .aee-contennt-handle-add {
            position: relative;
            height: 56px;
            .entity-row-top {
                display: flex;
                align-items: center;
                min-height: 56px;
                margin-bottom: 3px;
                position: relative;
                .entity-row-top-title {
                    width: 100px;
                    flex: none;
                    font-weight: bolder;
                }
                .l {
                    flex: none;
                    width: 192px;
                }

                .r {
                    color: #366aff;
                    margin-left: 10px;
                    padding: 0;
                    flex: auto;
                }
                .add-icon {
                    width: 21px;
                    height: 20px;
                    line-height: 20px;
                    color: #366aff;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 20px;
                    margin-left: 10px;
                    margin-right: 6px;
                    cursor: pointer;
                }
                .delete-icon {
                    width: 21px;
                    height: 20px;
                    line-height: 20px;
                    color: #366aff;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 20px;
                    margin-right: 16px;
                    cursor: pointer;
                }
                .delete-entity {
                    width: auto;
                    color: #366aff;
                    font-size: 16px;
                    line-height: 33px;
                    margin-left: 12px;
                    cursor: pointer;
                }
                .keyword-recommend {
                    position: relative;
                    // flex:1;
                }
            }
        }
        .aee-contennt-list {
            height: calc(100vh - 270px);
            overflow-y: auto;
            .entity-content-row:hover {
                background-color: #dbe5ff;
                .l {
                    .acrt-pre {
                        .acrt-pre-icon {
                            display: block;
                            cursor: pointer;
                        }
                    }
                }
                .r {
                    .acrs-pre {
                        .acrt-pre-icon {
                            display: block;
                            cursor: pointer;
                        }
                    }
                }
            }
            .entity-content-row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 12px 0;
                border-bottom: 1px solid #e0e6f7;
                .ach-text-num {
                    text-align: left;
                    width: 70px;
                    padding-left: 16px;
                    flex: none;
                }
                .l {
                    width: 150px;
                    padding-left: 16px;
                    flex: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .acrt-pre {
                        display: flex;
                        align-items: center;

                        .acrt-pre-icon {
                            display: none;

                            margin-right: 4px;
                            color: #366aff;
                            i {
                                font-size: 14px;
                            }
                        }

                        .acrt-pre-text {
                            text-align: left;
                        }
                    }

                    .acrt-edit {
                        display: flex;
                        align-items: center;
                        height: 32px;
                        line-height: 32px;
                        .el-input {
                            flex: auto;
                        }

                        .add-icon {
                            color: #366aff;
                            font-size: 20px;
                            cursor: pointer;
                            margin-right: 3px;
                            margin-left: 5px;
                        }
                        .delete-icon {
                            color: #366aff;
                            font-size: 20px;
                            cursor: pointer;
                        }
                    }
                }

                .r {
                    flex: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 16px;
                    .acrs-pre {
                        display: flex;
                        align-items: center;
                        .acrt-pre-icon {
                            display: none;
                            margin-right: 4px;
                            color: #366aff;
                            i {
                                font-size: 14px;
                            }
                        }

                        .acrt-pre-text {
                            text-align: left;
                        }
                    }

                    .acrs-edit {
                        flex: auto;
                        display: flex;
                        align-items: center;
                        height: 32px;
                        line-height: 32px;
                        .el-input {
                            flex: auto;
                        }

                        .add-icon {
                            color: #366aff;
                            font-size: 20px;
                            cursor: pointer;
                            margin-right: 3px;
                            margin-left: 5px;
                        }
                        .delete-icon {
                            color: #366aff;
                            font-size: 20px;
                            cursor: pointer;
                        }
                    }
                }

                .guoran-tongyichicun-16-09-shanchu2 {
                    text-align: left;
                    width: 42px;
                    padding-left: 16px;
                    flex: none;
                    cursor: pointer;
                    font-size: 16px;
                    color: #366aff;
                }
            }
        }
    }
}
</style>