<template>
    <div class="batch-import-intnet">
        <!-- 上传选择文件弹框 -->
        <popup @closeEvent="closeEvent" v-if="importFileVisible">
            <div slot="popup-name">{{$t('entityManagement.importEntity.title')}}</div>
            <div slot="popup-tip">
              {{$t('entityManagement.importEntity.tip')}}
            </div>
            <div slot="popup-con">
                <div class="content-import-file">
                    <el-upload
                        v-show="!haveFile"
                        ref="importIntentUpload"
                        class="upload-demo"
                        action="/api/v1/entity/entityWordImport"
                        accept=".xlsx,.xls"
                        :data="{
                            entityId: entityId,
                        }"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-error="uploadError"
                        :on-success="uploadSuccess"
                        :on-change="beforeUpload"
                    >
                        <div class="choose-file">{{$t('entityManagement.importEntity.choose')}}</div>
                    </el-upload>
                    <div class="have-file" v-show="haveFile">
                        <el-input
                            v-model="fileName"
                            size="small"
                            :disabled="disabled"
                        ></el-input>
                        <!-- <div class="iconfont guoran-a-16-15"></div> -->
                        <div
                            class="iconfont guoran-a-18-13"
                            @click="delFile"
                        ></div>
                    </div>
                    <div class="no-template">
                      {{$t('entityManagement.importEntity.download')}}
                        <span @click="downloadTemplate"
                            >{{$t('entityManagement.importEntity.template')}}</span
                        >
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    class="confirm-btn"
                    type="primary"
                    @click="submitForm('')"
                    round
                    :class="[haveFile ? 'have' : 'no']"
                    style="padding: 0 !important"
                    >{{$t('entityManagement.importEntity.upload')}}</el-button
                >
            </div>
        </popup>
        <!-- 上传成功弹框 -->
        <popup
            @closeEvent="closeSuccessEvent"
            v-if="successDialog"
            :haveTips="false"
            class="success-dialog"
        >
            <div slot="popup-name">{{$t('entityManagement.importEntity.importSuccessTitle')}}</div>
            <div slot="popup-con" style="padding: 0">
                <div class="down-load-tips">{{$t('entityManagement.importEntity.importSuccessTip')}}</div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="closeSuccessEvent"
                    plain
                    round
                    class="cancel-btn"
                    >{{$t('common.gotIt')}}</el-button
                >
            </div>
        </popup>
        <!-- 上传进度 -->
        <popup
            @closeEvent="cancelImport"
            v-if="progressDialog"
            :haveTips="false"
            :needCloseIcon="false"
        >
            <div slot="popup-name">{{$t('entityManagement.importEntity.uploading')}}</div>
            <div slot="popup-con">
                <div class="progress-box">
                    <img src="@/assets/images/import-excel.png" alt="" />
                    <div class="file-detail">
                        <div class="file-name">{{ fileName }}</div>
                        <el-progress
                            :percentage="progress"
                            :color="'#366AFF'"
                        ></el-progress>
                        <!-- <div class="progress-info">
                            正在上传，大约{{ surplusTime }}S
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <div slot="dialog-footer">
                <el-button @click="cancelImport" plain round class="cancel-btn">取消上传</el-button>
            </div> -->
        </popup>
        <!--  上传错误时 , 可下载 -->
        <errorDialog
            class="import-error-dialog"
            @closeEvent="showErrorDialog = false"
            v-if="showErrorDialog"
        >
            <div
                slot="popup-container"
                style="padding: 0"
                class="popup-container"
            >
                <div
                    class="prompt-text"
                    v-if="downLoadUrl && downLoadUrl !== ''"
                >
                  {{$t('entityManagement.importEntity.importFailTitle')}}
                </div>
                <div class="down-load-tips">
                    {{$t('entityManagement.importEntity.importFailTip')}}
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeErrorDialog" plain round>{{$t('common.gotIt')}}</el-button>
            </div>
        </errorDialog>
    </div>
</template>

<script>
import Popup from "@/components/popup.vue";
import errorDialog from "@/components/errorDialog.vue";
export default {
    data() {
        return {
            haveFile: false,
            fileName: "",
            disabled: false,
            successDialog: false,
            progressDialog: false,
            progress: 0,
            successMsg: {},
            downLoadUrl: "", // 上传错误时 , 下载的链接
            showErrorDialog: false,
            timer: null,
            surplusTime: 0,
        };
    },
    components: {
        Popup,
        errorDialog,
    },
    props: {
        importFileVisible: {
            default: false,
            type: Boolean,
        },
        successDialog: {
            default: false,
            type: Boolean,
        },
        progressDialog: {
            default: false,
            type: Boolean,
        },
        entityId: {
            type: String,
            default: "",
        },
    },
    watch: {
        importFileVisible(n, o) {
            if (n) {
                this.haveFile = false;
                this.disabled = false;
                this.fileName = "";
            }
        },
    },
    methods: {
        closeErrorDialog() {
            this.showErrorDialog = false;
        },
        downLoad() {
            window.location.href = this.downLoadUrl;
        },
        // 关闭成功的弹框
        closeSuccessEvent() {
            this.successDialog = false;
        },
        // 关闭进度弹框
        closeProprogressEvent() {
            this.progressDialog = false;
        },
        // 取消上传
        cancelImport() {
            // this.FetchGet(this.requestUrl.intent.cancelImport+'?apiKey=' + this.apiKey).then((res) => {
            //     if (res.code === "0") {
            //         clearInterval(this.timer);
            //         this.progressDialog = false;
            //         this.$message.success('导入任务取消');
            //     } else {
            //         this.$message.error(res.message);
            //     }
            // });
            this.progressDialog = false;
        },
        // 关闭弹框
        closeEvent() {
            this.$emit("update:importFileVisible", false);
        },
        // 获取文件后缀名
        getFileSuffix(url, arr) {
            let index1 = url.lastIndexOf(".") + 1;
            let index2 = url.length;
            let postf = url.substring(index1, index2); //后缀名
            if (!arr || arr.indexOf(postf) == -1) {
                return false;
            } else {
                return true;
            }
        },
        // 上传之前的钩子
        beforeUpload(file) {
            this.disabled = true;
            console.log(file, "上传之前的钩子");
            if (file.status === "ready") {
                if (!this.getFileSuffix(file.name, ["xls", "xlsx"])) {
                    this.$message.error(this.$t('entityManagement.importEntity.uploadType'));
                    return false;
                }
                //判断上传的视频大小
                if (file.size / 1024 / 1024 > 2) {
                    this.$message.error(this.$t('entityManagement.importEntity.uploadSize'));
                    return false;
                }
                this.fileName = file.name;
                if (this.fileName !== "") {
                    this.haveFile = true;
                }
            } else if (file.status === "fail") {
            } else if (file.status === "success") {
                // this.disabled = false;
                // this.haveFile = false;
                // this.fileName = ''
            }
        },
        // 上传失败
        uploadError() {
            this.$message.error(this.$t('entityManagement.importEntity.uploadFailMsg'));
            clearInterval(this.timer);
        },
        uploadSuccess(res) {
            console.log(res.code, "成功");
            this.progressDialog = false;
            if (res.code === "0") {
                // this.successMsg = res.data;
                this.successDialog = true;
                this.$emit("getNewEntityWord");
                setTimeout(() => {
                    this.successDialog = false;
                }, 4000);
                this.progress = 0;
            } else if (res.code === "-1") {
                this.downLoadUrl = res.message;
                this.showErrorDialog = true;
                setTimeout(() => {
                    this.showErrorDialog = false;
                }, 4000);
            } else {
                this.$message.error(res.message);
                this.haveFile = false;
                this.disabled = false;
                this.fileName = "";
            }
        },
        // 删除文件
        delFile() {
            this.fileName = "";
            this.haveFile = false;
        },
        // 下载模板
        downloadTemplate() {
            window.location.href = "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/front-oss/2024/05/31/17/17/ef5165c12d1827530cefeabb77db56cc/%E5%AE%9E%E4%BD%93%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20.xlsx";
        },
        // 保存
        submitForm() {
            let flag = true;
            if (this.fileName === "") {
                flag = false;
                this.$message.error(this.$t('entityManagement.importEntity.chooseMsg'));
                return false;
            }
            if (flag) {
                this.$refs.importIntentUpload.submit(); // 手动上传
                this.$emit("update:importFileVisible", false);
                this.progressDialog = true;
                this.progress = 30;
            }
        },
    },
};
</script>

<style scoped lang="less">
.batch-import-intnet {
    /deep/ #popup-assembly {
        width: 646px;
        .popup-header {
            padding: 55px 50px 50px !important;
        }
        .popup-container {
            overflow-y: inherit !important;
        }
    }
    /deep/ .el-form--label-top .el-form-item__label {
        float: left;
        &.after {
            clear: both;
        }
    }
    .content-import-file {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .tips {
            text-align: left;
            //white-space: nowrap;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 36px;
            > span:first-child {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: #a1b9ff;
                border-radius: 50%;
                text-align: center;
                line-height: 20px;
                color: #fff;
                margin-right: 5px;
            }
        }
        .have-file {
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
            .el-input {
                height: 34px;
                flex: auto;
            }
            .iconfont {
                width: 16px;
                flex: none;
                height: 16px;
                padding: 0;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                color: #366aff;
                border-radius: 5px;
                cursor: pointer;
                // &.guoran-a-16-15 {
                //     background-color: #366aff;
                //     border: 1px solid #366aff;
                //     color: #fff;
                //     margin: 0 10px;
                // }
                &.guoran-a-18-13 {
                    background: #ffffff;
                    // border: 1px solid #366aff;
                    margin-left: 10px;
                }
            }
        }
        .choose-file {
            width: 116px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #b7caff;
            border-radius: 19px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #366aff;
            text-align: center;
            line-height: 40px;
            margin: 25px 0;
        }
        .no-template {
            height: 15px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            text-align: center;
            padding-bottom: 26px;
            span {
                color: #366aff;
                cursor: pointer;
            }
        }
        .upload-demo {
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;
        }
    }
    /deep/ .dialog-footer {
        padding-top: 46px !important;
        .el-button {
            // width: 99px;
            height: 37px;
            padding: 0;
            text-align: center;
        }
        .have {
            background: #366aff;
            border-color: #366aff;
        }
        .no {
            background: #e0e5f0;
            border-color: #e0e5f0;
        }
        .down-load {
            width: 139px;
            height: 37px;
            background: #ffffff;
            border: 2px solid #c7d5ff;
            border-radius: 19px;
            font-size: 14px;
            color: #366aff;
            cursor: pointer;
        }
    }
    .success-import-intent {
        display: flex;
        align-items: center;
        height: 140px;
        padding: 0 30px;
        .circle-item {
            width: 71px;
            height: 71px;
            background: #ecf1ff;
            border-radius: 50%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 24px;
            .icon-box {
                width: 24px;
                height: 24px;
                background: #749bff;
                border-radius: 50%;
                position: absolute;
                top: 4px;
                right: -11px;
                color: #749bff;
                z-index: 2;
                text-align: center;
                line-height: 24px;
                .iconfont {
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
        .left {
            display: flex;
            position: relative;
            margin-right: 50px;
            height: 140px;
            .border {
                width: 89px;
                height: 89px;
                border: 3px solid #d9e3ff;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                .circle-item {
                    width: 69px;
                    height: 69px;
                    background: #366aff;
                    border-radius: 50%;
                    border: 1px solid #d9e3ff;
                    border-radius: 50%;
                }
            }
            .bottom-text {
                margin-top: 22px;
                color: #366aff;
                font-size: 16px;
                white-space: nowrap;
            }
            .mask-squer {
                position: absolute;
                background-color: #fff;
                width: 30px;
                height: 40px;
                bottom: 50px;
                right: -17px;
                border-radius: 12px;
            }
            .line-right {
                width: 2px;
                height: 57px;
                background: #a2baff;
                margin-left: 70px;
                margin-top: 17px;
            }
        }
        .right {
            display: flex;
            align-items: center;
            height: 140px;
            margin-top: 10px;
            .circle-item {
                background: #ecf1ff;
                font-size: 20px;
                color: #749bff;
                .icon-box {
                    right: -8px;
                }
            }
            .left.right {
                display: flex;
                flex-direction: column;
                width: 71px;
                height: 71px;
                .bottom-text {
                    margin-top: 30px;
                    color: #fff;
                    font-size: 16px;
                    white-space: nowrap;
                }
            }
            .right-update {
                margin-left: 100px;
            }
            .bottom-text {
                margin-top: 40px;
                color: #616161;
                font-size: 16px;
                white-space: nowrap;
            }
        }
    }
    .down-load-tips {
        font-size: 14px;
        font-weight: 400;
        color: #a3adc6;
        margin-top: 27px;
        span {
            color: #366aff;
            cursor: pointer;
        }
    }
    .progress-box {
        background: #ffffff;
        border: 1px dotted #e0e6f7;
        border-radius: 1px;
        padding: 20px 35px 12px 35px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 74px;
        img {
            width: 33px;
            height: 38px;
            margin-right: 11px;
            margin-top: -5px;
        }
        .file-detail {
            width: 100%;
            .file-name {
                height: 15px;
                line-height: 15px;
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                text-align: left;
                margin-bottom: 11px;
            }
            /deep/ .el-progress {
                width: 100%;
                text-align: left;
                .el-progress__text {
                    position: absolute;
                    bottom: 20px;
                    right: 50px;
                    margin-left: 0;
                }
                .el-progress-bar__outer {
                    background-color: #e8eeff;
                }
            }
            .progress-info {
                text-align: left;
                margin-top: 16px;
                font-size: 14px;
                font-weight: 400;
                color: #a9b3c6;
                line-height: 14px;
            }
        }
    }
    /deep/ #delete-dialog {
        #delete-popup-assembly {
            width: 646px !important;
        }
        &.import-error-dialog {
            .down-load-tips {
                font-size: 14px;
                font-weight: 400;
                color: #a3adc6;
                margin-top: 27px;
                span {
                    color: #366aff;
                    cursor: pointer;
                }
            }
            .prompt-text {
                font-size: 22px;
                color: #000 !important;
            }
        }
    }
}
</style>