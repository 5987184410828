<template>
    <div id="datainterconnectionOperate">
        <ul class="datainterconnection">
            <li class="item-config">
                <div class="block-add-intent">
                    <span class="title"
                        >{{$t('entityManagement.dataConnection.title')}} <span class="red-text">*</span>
                    </span>
                    <el-select
                        v-model="dataInterObj.webhookId"
                        size="small"
                        :placeholder="$t('entityManagement.dataConnection.selectWebhook')"
                    >
                        <el-option
                            v-for="item in webhookList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </li>
            <li class="item-config-other">
                <span class=""></span>
                <span class="gotoWebhook" @click="gotoWebhook"
                    >{{$t('entityManagement.dataConnection.config')}}</span
                >
            </li>
            <li class="item-config">
                <div class="block-add-intent">
                    <span class="title">{{$t('entityManagement.dataConnection.timedTasks')}}</span>
                    <div class="ask-el-radio-group">
                        <el-radio-group
                            size="medium"
                            v-model="dataInterObj.taskType"
                        >
                            <el-radio :label="0">{{$t('entityManagement.dataConnection.designatedTime')}}</el-radio>
                            <el-radio :label="1">{{$t('entityManagement.dataConnection.fixedTime')}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </li>
            <li v-if="dataInterObj.taskType == 0" class="item-config">
                <div class="block-add-intent">
                    <div
                        v-for="(cell, cellIndex) in dataInterObj.taskTimeList"
                        :key="cellIndex"
                        class="task-time-cell"
                    >
                        <span class="task-time-cell-text">{{$t('entityManagement.dataConnection.everyday')}}</span>
                        <div class="task-time-cell-time">
                            <el-time-select
                                v-model="dataInterObj.taskTimeList[cellIndex]"
                                size="small"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:30',
                                    end: '23:00',
                                }"
                                :placeholder="$t('entityManagement.dataConnection.selectTime')"
                            >
                            </el-time-select>
                        </div>
                        <span class="task-time-cell-handle">
                            <i
                                v-if="cellIndex != 0"
                                @click="handleTaskTime('delete', cellIndex)"
                                class="iconfont guoran-tongyichicun-16-09-shanchu2"
                            ></i>
                            <i
                                v-else
                                @click="handleTaskTime('add')"
                                class="iconfont guoran-tianjiadao"
                            ></i>
                        </span>
                    </div>
                </div>
            </li>
            <li v-if="dataInterObj.taskType == 1" class="item-config">
                <div class="block-add-intent">
                    <div class="task-time-sing">
                        <span class="task-time-sing-text">{{$t('entityManagement.dataConnection.each')}}</span>
                        <el-select
                            v-model="dataInterObj.specifiedTimeInterval"
                            size="small"
                            :placeholder="$t('common.selectPlaceholder')"
                        >
                            <el-option
                                v-for="item in Array.from(
                                    { length: 24 },
                                    (_, index) => index + 1
                                )"
                                :key="item"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                            v-model="dataInterObj.timeUnit"
                            size="small"
                            :placeholder="$t('common.selectPlaceholder')"
                        >
                            <el-option
                                v-for="item in [
                                    { label: $t('entityManagement.dataConnection.hour'), value: 0 },
                                    { label: $t('entityManagement.dataConnection.day'), value: 1 },
                                ]"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <span class="task-time-sing-text-after"
                            >{{$t('entityManagement.dataConnection.onceData')}}</span
                        >
                    </div>
                    <div class="task-time-sing-des">
                        {{$t('entityManagement.dataConnection.taskTip')}}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            webhookList: [],
            dataInterObj: {
                id: 6, // 数据互联任务ID，仅修改操作传值，新增操作该值不传
                entityId: 6832, // 实体ID
                webhookId: 253, // 关联webhookId
                taskTimeList: ["10:25", "10:26"], // 指定时间执行入参格式,
                specifiedTimeInterval: 1,
                // 固定时间间隔执行入参格式如，每1小时/天获取数据，taskTime值传 "1"
                taskType: 0, // 任务类型：0，每天指定时间执行，1，固定时间间隔执行
                timeUnit: 0, // 时间单位 0 小时，1 天(仅当设置为固定时间间隔执行用到)
            },
            getDataIntertimer: null,
        };
    },
    props: ["entityId"],
    methods: {
        handleTaskTime(type, cellIndex) {
            if (type == "delete") {
                this.dataInterObj.taskTimeList.splice(cellIndex, 1);
            } else {
                this.dataInterObj.taskTimeList.push("");
            }
        },
        gotoWebhook() {
            window.open(
                window.location.protocol +
                    "//" +
                    window.location.host +
                    "/#/main/webhook",
                "_blank"
            );
        },
        // 获取webhookbaselist
        getWebhookBaseList() {
            this.FetchGet(this.requestUrl.webhook.webhookbaseList, {
                status: 1,
            }).then((res) => {
                if (res.code === "0") {
                    this.webhookList = res.data;
                    this.getDataInterconnectionOperate();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 查询实体数据互联配置
        getDataInterconnectionOperate() {
            this.FetchGet(
                this.requestUrl.entity.selectDataInterconnectionOperate,
                {
                    entityId: this.entityId,
                }
            ).then((res) => {
                if (res.code === "0") {
                    if (!res.data) {
                        this.dataInterObj = {
                            entityId: this.entityId, // 实体ID
                            webhookId: "", // 关联webhookId
                            taskTimeList: ["01:00"], // 指定时间执行入参格式,
                            specifiedTimeInterval: 1,
                            // 固定时间间隔执行入参格式如，每1小时/天获取数据，taskTime值传 "1"
                            taskType: 0, // 任务类型：0，每天指定时间执行，1，固定时间间隔执行
                            timeUnit: 0, // 时间单位 0 小时，1 天(仅当设置为固定时间间隔执行用到)
                        };
                    } else {
                        this.dataInterObj = res.data;
                        if (this.dataInterObj.taskTimeList == null) {
                            this.dataInterObj.taskTimeList = ["01:00"];
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        saveDataInter(callback) {
            if (
                this.dataInterObj.webhookId == null ||
                this.dataInterObj.webhookId == ""
            ) {
                this.$message.error(this.$t('entityManagement.dataConnection.selectWebhook'));
                return;
            }

            let param = JSON.parse(JSON.stringify(this.dataInterObj));
            let tampList = [...new Set(param.taskTimeList)];
            if (tampList.includes("") || tampList.includes(null)) {
                this.$message.error(this.$t('entityManagement.dataConnection.timeMsg'));
                return;
            }

            if (param.taskType == 0) {
                param.specifiedTimeInterval = null;
                param.timeUnit = null;
                param.taskTimeList = [...new Set(param.taskTimeList)];
            }
            this.FetchPost(
                this.requestUrl.entity.dataInterconnectionOperate,
                param
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success(this.$t('entityManagement.dataConnection.saveSuccess'));
                    callback();
                    setTimeout(() => {
                        this.getDataInterconnectionStatus();
                    }, 50);
                    this.getDataIntertimer = setInterval(() => {
                        this.getDataInterconnectionStatus();
                    }, 10000);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        getDataInterconnectionStatus() {
            this.FetchGet(this.requestUrl.entity.selectDataInterconnection, {
                entityId: this.entityId,
            }).then((res) => {
                if (res.code === "0") {
                    this.$emit("changeDataConnecting", res.data);
                    if (!res.data) {
                        console.log(247);
                        this.$emit("getNewEntityWord");
                        console.log(249);
                        clearInterval(this.getDataIntertimer);
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
    mounted() {
        this.getWebhookBaseList();
    },
};
</script>
<style lang="less">
.item-config-other {
    display: flex;
    justify-content: space-between;
    .gotoWebhook {
        cursor: pointer;
        color: #366aff;
    }
}
.item-config {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 14px 0px;
    font-size: 14px;
    .task-time-sing {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .task-time-sing-text {
            width: 18px;
            flex: none;
            text-align: left;
        }
        .el-select {
            flex: auto;
            margin: 0 6px;
        }
        .task-time-sing-text-after {
            width: 100px;
            flex: none;
        }
    }
    .task-time-sing-des {
        font-size: 12px;
        color: #a9b3c6;
        margin-top: 15px;
        text-align: left;
    }
    .task-time-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 6px 0;
        .task-time-cell-text {
            flex: none;
            width: 50px;
            text-align: left;
        }
        .task-time-cell-time {
            flex: auto;
            .el-date-editor {
                width: 100%;
            }
        }
        .task-time-cell-handle {
            width: 30px;
            flex: none;
            text-align: center;
            i {
                font-size: 16px;
                cursor: pointer;
                color: #366aff;
            }
        }
    }
    .title {
        color: #616161;
        font-weight: 700;
        .red-text {
            color: red;
        }
    }
    .ask-el-radio-group {
        display: flex;
        align-items: center;
        height: 40px;
        background: #fbfcfd;
        border-radius: 5px;
        text-align: left;
        padding-left: 10px;
    }

    .block-add-intent {
        width: 100%;
        display: flex;
        flex-direction: column;
        .title {
            text-align: left;
            margin-bottom: 10px;
            display: flex;
            align-items: centers;
        }
    }
}
</style>